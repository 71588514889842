<template>
  <div class="FilterBox">
    <van-action-sheet v-model="show" title=" ">
      <div class="contentBox">
        <TextBox color="title pd30">类型</TextBox>
        <Space :height="28"></Space>
        <van-field
          placeholder=""
          :value="selectList[selectActive].description"
          class="bg radius10"
          readonly
          @click="selectShow = true"
        >
          <template #button>
            <van-icon name="arrow-down" color="#9EA2B5" />
          </template>
        </van-field>
        <van-popup v-model="selectShow" round position="bottom">
          <van-picker
            show-toolbar
            :columns="selectList.map(item => item.description)"
            @cancel="selectShow = false"
            @confirm="selectConfirm"
          />
        </van-popup>
        <Space :height="30"></Space>
        <TextBox color="title pd30">时间</TextBox>
        <Space :height="28"></Space>
        <van-field placeholder="" :value="timeShowValue" class="bg radius10" readonly @click="timeShow = true">
          <template #button>
            <van-icon :name="require('@/assets/images/personal/timeSelect.png')" color="#9EA2B5" />
          </template>
        </van-field>
        <Space :height="76"></Space>
        <van-button block type="primary" class="radius10" @click="submit" :loading="$store.state.submitLoading">
          确定
        </van-button>
        <Space :height="33"></Space>
      </div>
      <van-calendar
        get-container="body"
        color="#525298"
        v-model="timeShow"
        type="range"
        @confirm="timeConfirm"
        :show-title="false"
        :show-confirm="false"
        :min-date="$dayjs(new Date()).subtract(1, 'year').$d"
        :max-date="new Date()"
        :allow-same-day="true"
      />
    </van-action-sheet>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      show: false,
      selectActive: 0,
      selectList: [
        {
          description: '',
        },
      ],
      selectShow: false,
      time: {
        start: '',
        end: '',
      },
      timeShow: false,
    };
  },
  // created () {},
  mounted() {},
  methods: {
    submit: _.debounce(function() {
      this.$parent.filter = {
        type: this.selectList[this.selectActive].type,
        time: this.time,
      };
      this.$parent.$refs.listView.reset();
      this.show = false;
    }),
    init() {
      this.show = true;
      this.getTypeList();
    },
    getTypeList() {
      this.$http('get', '/v1/assets/assetsBillTypeRecord').then(res => {
        res.data.unshift({
          type: '',
          description: '全部',
        });
        this.selectList = res.data;
      });
    },
    selectConfirm(res, index) {
      this.selectActive = index;
      this.selectShow = false;
    },
    timeConfirm(res) {
      this.time.start = this.$dayjs(res[0]).format('YYYY-MM-DD');
      this.time.end = this.$dayjs(res[1]).format('YYYY-MM-DD');
      this.timeShow = false;
    },
  },
  // watch: {},
  computed: {
    timeShowValue() {
      return `${this.time.start} ~ ${this.time.end}`;
    },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less"></style>
