<template>
  <div class="moneyHistoryBox">
    <NavBar title="收益明细" right-icon="add">
      <template #right>
        <van-icon
          color="red"
          :name="require('@/assets/images/personal/c1.png')"
          :size="parseInt($pxToPxRatio(40), 10)"
          @click="showFilter"
        ></van-icon>
      </template>
    </NavBar>
    <van-sticky :offset-top="$pxToVw(92)">
      <van-tabs v-model="active">
        <van-tab title="一期"></van-tab>
        <van-tab title="二期"></van-tab>
        <van-tab title="全部"></van-tab>
      </van-tabs>
    </van-sticky>
    <van-divider />
    <div class="contentBox flexAuto flexColumn">
      <Space :height="30"></Space>
      <ListView :get-list="getList" empty="暂无记录" pull-refresh ref="listView">
        <template v-slot="{ data }">
          <div class="listBox radius10" v-for="(item, index) of data" :key="index">
            <div>
              <div class="title">{{ item.title }}</div>
              <div class="time">{{ item.time }}</div>
            </div>
            <div class="intro">{{ item.intro }}</div>
          </div>
        </template>
      </ListView>
    </div>
    <FilterBox ref="filterBox"></FilterBox>
  </div>
</template>
<script>
import FilterBox from './component/Filter.vue';
export default {
  name: '',
  // mixins: [],
  components: { FilterBox },
  // props: {},
  data() {
    return {
      filter: {
        type: '',
        time: {
          start: '',
          end: '',
        },
      },
      active: 0,
    };
  },
  created() {
    this.active = this.$store.state.personal.userActive;
  },
  mounted() {
    this.$store.commit('setBodyColor');
  },
  methods: {
    async getList(page) {
      let res = await this.$http('get', '/v1/assets/assetsBillRecord', {
        page: page,
        size: 10,
        beginTime: this.filter.time.start,
        endTime: this.filter.time.end,
        type: this.filter.type,
        goodsId: (this.active + 1) % 3 || '',
      });
      res.data.list = res.data.list.map(item => {
        return {
          title: item.description,
          time: this.$dayjs(item.createTime).format('YYYY/MM/DD HH:mm:ss'),
          intro: `${item.direction === 1 ? '+' : ''}${item.amount} ${item.coinName}`,
        };
      });
      return res;
    },
    showFilter() {
      this.$refs.filterBox.init();
    },
  },
  watch: {
    active: {
      handler(res) {
        this.$refs.listView.reset();
      },
    },
  },
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.listBox {
  background: @white;
  display: flex;
  justify-content: space-between;
  padding: 30px;
  margin-bottom: 30px;
  div {
    .title {
      color: @title-color;
    }
    .time {
      font-size: 24px;
      color: @intro-color;
      margin-top: 14px;
    }
  }
  .intro {
    font-size: 30px;
    color: @title-color;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
